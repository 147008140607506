import Vue from 'vue'
import VueRouter from 'vue-router'

import { isUserLoggedIn,getUserData } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/bnp.vue'),
      //component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    }
    ,
    {
      path: '/admin-dashboard',
      name: 'admin_dashboard',
      props: true,
      component: () => import('@/views/district-dashboard/DistrictDashboard.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Community Based Activity',
        breadcrumb: [
          {
            text: 'Project Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/uns-dashboard',
      name: 'uns_dashboard',
      props: true,
      component: () => import('@/views/uns-dashboard/UNSDashboard.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Community Based Activity',
        breadcrumb: [
          {
            text: 'UNS Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cc',
      name: 'cc',
      props: true,
      component: () => import('@/views/cc/List.vue'),
      meta: {
        pageTitle: 'Facility',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/villages',
      name: 'villages',
      props: true,
      component: () => import('@/views/village/List.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Village',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/wards',
      name: 'wards',
      props: true,
      component: () => import('@/views/ward/List.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Ward',
        breadcrumb: [
          {
            text: 'Ward List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/unions',
      name: 'unions',
      props: true,
      component: () => import('@/views/union/List.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Union',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/epi',
      name: 'epi',
      props: true,
      component: () => import('@/views/epi/List.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'EPI Center',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/map',
      name: 'map',
      props: true,
      component: () => import('@/views/Map.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Map',
        breadcrumb: [
          {
            text: 'Map',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gov-employee',
      name: 'gov-employee',
      props: true,
      component: () => import('@/views/employee/gov.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Govt',
        breadcrumb: [
          {
            text: 'Employee List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/unicef-employee',
      name: 'unicef-employee',
      props: true,
      component: () => import('@/views/employee/unicef.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'UNICEF',
        breadcrumb: [
          {
            text: 'Employee List',
            active: true,
          },
        ],
      },
    }
    ,
    {
      path: '/filter',
      name: 'filter',
      props: true,
      component: () => import('@/views/FilterTest.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'filter',
        breadcrumb: [
          {
            text: 'filter',
            active: true,
          },
        ],
      },
    },
    {
      path: '/epi-update/:id',
      name: 'epi-update',
      props: true,
      component: () => import('@/views/epi/Add.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'EPI Center',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ward-update/:id',
      name: 'ward-update',
      props: true,
      component: () => import('@/views/ward/Add.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add New',
        breadcrumb: [
          {
            text: 'Ward',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cc-update/:id',
      name: 'cc-update',
      props: true,
      component: () => import('@/views/cc/Add.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Facility',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gov-employee-update/:id',
      name: 'gov-employee-update',
      props: true,
      component: () => import('@/views/employee/add-gov.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Govt. Employee',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/unicef-employee-update/:id',
      name: 'unicef-employee-update',
      props: true,
      component: () => import('@/views/employee/add-unicef.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'UNICEF Employee',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/union-update/:id',
      name: 'union-update',
      props: true,
      component: () => import('@/views/union/Add.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Union',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/village-update/:id',
      name: 'village-update',
      props: true,
      component: () => import('@/views/village/Add.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Village',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/todolist',
      name: 'todolist',
      props: true,
      component: () => import('@/views/todolist.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Monthly Todo List',
        breadcrumb: [
          {
            text: 'P4P',
            active: true,
          },
        ],
      },
    },
    {
      path: '/todo-template',
      name: 'todo-template',
      props: true,
      component: () => import('@/views/todo-template.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Monthly Task List of P4P',
        breadcrumb: [
          {
            text: 'Predefined Rules',
            active: true,
          },
        ],
      },
    },
    {
      path: '/heatmap',
      name: 'heatmap',
      props: true,
      component: () => import('@/views/Heatmap.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Heatmap',
        breadcrumb: [
          {
            text: 'Analysis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/p4p-analysis',
      name: 'p4p-analysis',
      props: true,
      component: () => import('@/views/analysis/P4P.vue'),
      meta: {
        pageTitle: 'P4P Analysis',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'P4P Analysis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ssa-questions',
      name: 'ssa-questions',
      props: true,
      component: () => import('@/views/analysis/ssa/question.vue'),
      meta: {
        pageTitle: 'Supervision Checklist',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'SSA',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ssa-analysis',
      name: 'ssa-analysis',
      props: true,
      component: () => import('@/views/analysis/ssa/analysis.vue'),
      meta: {
        pageTitle: 'Performance Indicator Analysis',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Supportive Supervision Allowance',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      props: true,
      component: () => import('@/views/calendar/Calendar.vue'),
      meta: {
        pageTitle: 'Session Calendar',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Calendar',
            active: true,
          },
        ],
      },
    },
    {
      path: '/demo',
      name: 'demo',
      props: true,
      component: () => import('@/views/demo.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Microplan Submit',
        breadcrumb: [
          {
            text: 'From Mobile Apps',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/todo-template',
    //   name: 'todo-template',
    //   component: () => import('@/views/todo-template.vue'),
    //   meta: {
    //     pageTitle: 'Monthly Todo List',
    //     breadcrumb: [
    //       {
    //         text: 'P4P',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/employee',
      name: 'employee',
      props: true,
      component: () => import('@/views/employee/List.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Employee List',
        breadcrumb: [
          {
            text: 'Employee',
            active: true,
          },
        ],
      },
    },
    {
      path: '/single-employee/:id',
      name: 'single-employee',
      props: true,
      component: () => import('@/views/employee/Employee.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Employee ',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
      }
    },
    {
      path: '/profile',
      name: 'profile',
      props: true,
      component: () => import('@/views/employee/profile.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Self',
            active: true,
          },
        ],
      }
    },{
      path: '/bnp',
      name: 'bnp',
      //props: true,
      component: () => import('@/views/bnp.vue'),
      meta: {
        //requiresAuth: false,
        //pageTitle: 'Bnp',
        // breadcrumb: [
        //   {
        //     text: 'BNP',
        //     active: true,
        //   },
        // ],
      }
    },
    {
      path: '/session-dtls/:id',
      name: 'single-session',
      props: true,
      component: () => import('@/views/session/single.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Session',
        breadcrumb: [
          {
            text: 'Session',
            active: true,
          },
        ],
      },
    },
    {
      path: '/session-site-dtls/:id',
      name: 'session-site-dtls',
      props: true,
      component: () => import('@/views/session/session-site-dtls.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Session Site',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/session-site',
      name: 'session-site',
      props: true,
      component: () => import('@/views/session/sessionsite.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Session Site',
        breadcrumb: [
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/session-site-update/:id',
      name: 'session-site-update',
      props: true,
      component: () => import('@/views/session/add-site.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Session Site',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: () => import('@/views/Login.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // }, 
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  const userData = getUserData();

  if (to.name == 'login') {
    if (isLoggedIn) next({ name: 'admin_dashboard' })
  }
  else if(to.name == 'admin_dashboard') {
    if(userData.user_type.id==5) next({ name: 'uns_dashboard' });
    else next();
  }
  else {
    if (to.meta.requiresAuth && isLoggedIn) {
      next();
    }
    else next({ name: 'login' });
  }
  
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
