import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import axios from 'axios'
import router from './router'
import store from './store'
import App from './App.vue'
import Dayjs from "vue-dayjs";



// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
library.add(faUserSecret)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(Dayjs);
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Composition API
Vue.use(VueCompositionAPI)


// Tailwind Css
require('@/assets/tailwind.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')


Vue.config.productionTip = false

Vue.use({
  // eslint-disable-next-line no-shadow
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$api = axios.create({
      baseURL: process.env.VUE_APP_TUNNEL_BASE_API,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
      },
    })
  },
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
