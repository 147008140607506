import Vue from 'vue'

// axios
import axios from 'axios'
var bu = process.env.VUE_APP_TUNNEL_BASE_API;
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: bu,
  crossDomain: true,
  headers: {
  "Access-Control-Allow-Origin": bu,
  "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
  }
})


Vue.prototype.$http = axiosIns

export default axiosIns
